import React from "react"

import styled from "styled-components"

import Container from "components/container"
import Hero from "components/hero"
import Layout from "components/layout"

const BackLink = styled.a`
  display: block;
  margin-top: 20px;
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default ({ data }) => (
  <Layout
    title="Ember launches UK’s first all-electric intercity bus service"
    description="Ember launches the UK’s first all-electric intercity bus service and begins services between Dundee and Edinburgh"
  >
    <Hero
      title="Ember launches the UK’s first all-electric intercity bus service"
      subtitle="Service runs between Dundee and Edinburgh and will launch on 1st October 2020"
    >
      <BackLink href="/press">Back to all press</BackLink>
    </Hero>
    <Container size="narrow">
      <p>
        Ember is to revolutionise bus services in the UK this week with the
        launch of the country’s first ever bus service between two major cities
        using purely electric power.
      </p>

      <p>
        Starting with services up to eight times a day in each direction, Ember
        will operate the 60-mile distance between Dundee and Edinburgh entirely
        on one charge of its batteries and without the need for a top-up during
        the journey. There are zero emissions during the journey.
      </p>

      <p>
        The services will briefly stop en-route at Inchture, Perth, Kinross and
        Rosyth. The fare is a set rate of £7.50 one-way and tickets can be
        bought via app right up to the moment of departure, or onboard for an
        additional £2.
      </p>

      <p>
        Each bus is fitted with complementary 5G superfast wifi as well as USB
        chargers at every seat. The seats also recline enabling naps to be taken
        during the journey.
      </p>

      <p>
        Ember Co-Founder Keith Bradbury says: “People in the past have
        associated buses and coaches with discomfort. There’s no reason why with
        these new Ember buses which produce zero emissions, are quiet, produce
        no diesel or smelly fumes and are environmentally how we should all be
        travelling between major cities in future.
      </p>

      <p>
        “We hope to be running 30 buses within two years across Scotland if all
        goes to plan and then expand further across the UK.”
      </p>

      <p>
        “These services are the ultimate in responsible travel, and ideal for
        business and leisure travellers alike. Our drivers are highly-trained,
        ensuring the journey is safe and problem-free and crucially enable you
        to work onboard without interruption or simply enjoy the stunning
        scenery en route.”
      </p>

      <p>Ember has thought about every aspect of the traveller experience:</p>

      <ul>
        <li>
          Comfortable half-leather seats which recline to give extra legroom
        </li>
        <li>USB and Wi-Fi enabling even easier connectivity</li>
        <li>
          Live tracking enables you to track your bus to the second, meaning
          you’re not waiting around
        </li>
        <li>
          Friendly drivers, there to help you with bags or fold-up bicycles
          which can be taken onboard
        </li>
        <li>Book a wheelchair or bike space in advance, for free</li>
        <li>
          Flexible tickets enable you to cancel for free anytime before
          departure{" "}
        </li>
      </ul>

      <p>
        <strong>
          More information can be found at <a href="/">www.ember.to</a>
        </strong>
      </p>

      <p>
        <strong>
          For media enquiries contact Paul Charles / Michael Johnson / Balint
          Brunner via The PC Agency on <a href="/">ember@pc.agency</a>
        </strong>
      </p>
    </Container>
  </Layout>
)
